import KexLink from 'Kex/KexLink';
import ProductImage from 'Atoms/Image/ProductImage';
import { styled } from 'stitches.config';
import SearchLinkResultModel from 'Models/Search/SearchLinkResultModel.interface';
import ProductCardTitle from 'Atoms/ProductCardTitle/ProductCardTitle';
import { isEmpty } from 'Shared/Common/Helpers';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

type PropTypes = {
  item: SearchLinkResultModel;
  onClick: () => void;
};

function SearchCard({ item, onClick }: PropTypes) {
  const { image, name, url, nowPrice, isUsed } = item;
  const { missingImage } = useAppSettingsData();
  let itemImage = image;

  if (isEmpty(itemImage)) {
    itemImage = missingImage;
  }
  return (
    <CartItemContainer>
      <KexLink css={{ mb: 0 }} onClick={onClick} href={url} underlined={false}>
        <ImageContainer>
          <ProductImage
            src={
              itemImage && isUsed
                ? `${itemImage?.src}?preset=small-product-card-used-desktop`
                : `${itemImage?.src}?preset=small-product-card-desktop`
            }
            alt={image?.alt}
          />
        </ImageContainer>
      </KexLink>
      <Details>
        <TopWrapper>
          <StyledKexLink href={url} onClick={onClick} underlined={false}>
            <ProductCardTitle title={name} />
          </StyledKexLink>
        </TopWrapper>
        <div>{nowPrice?.priceRoundedAsString}</div>
      </Details>
    </CartItemContainer>
  );
}

const StyledKexLink = styled(KexLink, {
  fontFamily: '$fontSecondary600',
  wordSpacing: 'unset',
});

const CartItemContainer = styled('div', {
  display: 'flex',
  position: 'relative',

  '&:not(:last-child)': {
    py: 5,
    borderBottomWidth: '1px',
    borderBottomColor: '$checkoutPageBackgroundPrimary',
  },
  '&:last-child': {
    pt: 5,
    mb: 6,
  },
  variants: {
    isMiniCart: {
      true: {
        '&:not(:last-child)': {
          py: 5,
          borderBottomWidth: '1px',
          borderBottomColor: '$primary2',
        },
      },
    },
  },
});

const ImageContainer = styled('div', {
  width: 96,
  height: 72,
  mr: 4,
  '@mediaMinLarge': {
    width: 104,
    height: 80,
  },
});

const TopWrapper = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});
const Details = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export default SearchCard;
