let timer: NodeJS.Timeout | null = null;

export const debounce = (
  fn: () => void,
  delay: number,
  abort: boolean = false
) => {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(() => {
    if (abort) return;
    fn();
  }, delay);
};
