import KexLink from 'Kex/KexLink';
import { styled } from 'stitches.config';
import { LogoIcon, HamburgerMenuIcon, CloseIcon } from 'Atoms/Icons';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import MobileQuickSearch from 'Organisms/QuickSearch/MobileQuickSearch/MobileQuickSearch';
import MobileMenu from 'Organisms/Header/MobileMenu/MobileMenu';
import { useCallback, useReducer } from 'react';
import menuReducer from '../Reducers/menuReducer';
import loadable, { LoadableComponent } from '@loadable/component';
import favoritesReducer from '../Reducers/favoritesReducer';
import { PropType as FavoritesPropType } from 'Shared/Favorites/FavoritesModal';
import { animation, timings } from 'Theme/Settings/animation';

import { useStickyHeaderData } from 'Shared/Providers/StickyHeaderProvider';

const MobileNavigationMenu = loadable(
  () => import('../NavigationMenu/MobileNavigationMenu')
);

const FavoritesModal: LoadableComponent<FavoritesPropType> = loadable(
  () => import('Shared/Favorites/FavoritesModal')
);

function MobileHeader() {
  const { languageRoute } = useAppSettingsData();
  const [{ menuIsOpen, mountMenu }, menuDispatch] = useReducer(menuReducer, {
    menuIsOpen: false,
    mountMenu: true,
    menuId: null,
  });

  const onMenuClose = useCallback(() => {
    menuDispatch({ type: 'closeMenu' });
  }, [menuDispatch]);

  const [{ favoritesIsOpen, mountFavorites }, favoritesDispatch] = useReducer(
    favoritesReducer,
    {
      favoritesIsOpen: false,
      mountFavorites: true,
    }
  );

  const { isHideHeader, shouldAnimateSticky } = useStickyHeaderData();

  return (
    <>
      <Placeholder />
      <MobileQuickSearch
        onMenuClose={() => menuDispatch({ type: 'closeMenu' })}
      />
      <HeaderWrapper showTransition={isHideHeader} sticky={shouldAnimateSticky}>
        <HeaderLeftWrapper>
          <button
            aria-label={'menu button'}
            onClick={() => {
              menuDispatch(
                menuIsOpen ? { type: 'closeMenu' } : { type: 'mountAndOpen' }
              );
            }}
          >
            {!menuIsOpen ? (
              <HamburgerMenuIcon size="m" color="secondary" />
            ) : (
              <CloseIcon size="m" fillColor="secondary" />
            )}
          </button>
          <StyledKexLink
            href={`/${languageRoute}`}
            underlined={false}
            aria-label={'home button'}
          >
            <LogoIcon size="headerMobile" fillColor="white" />
          </StyledKexLink>
        </HeaderLeftWrapper>
        <HeaderRightWrapper>
          <MobileMenu
            onMenuClose={onMenuClose}
            toggleFavorites={() => favoritesDispatch('mountAndOpen')}
          />
        </HeaderRightWrapper>
      </HeaderWrapper>
      {mountMenu && (
        <MobileNavigationMenu
          isOpen={menuIsOpen}
          onMenuClose={() => menuDispatch({ type: 'closeMenu' })}
        />
      )}
      {mountFavorites && (
        <FavoritesModal
          isOpen={favoritesIsOpen}
          closeFavorites={() => {
            favoritesDispatch('close');
          }}
        />
      )}
    </>
  );
}

export default MobileHeader;

const HeaderWrapper = styled('header', {
  position: 'relative',
  t: 0,
  px: 4,
  w: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '$siteHeaderMobileBackground',
  zIndex: '$HeaderMobile',
  transition: `top ${timings.oneFifth} ${animation.timingFn}`,
  height: '$mobileHeaderHeight',
  border: 'none',
  variants: {
    showTransition: {
      // animate down
      true: {
        t: -16,
      },
      // animate up
      false: {
        t: -32,
        position: 'sticky', // set sticky again to animate up
      },
    },
    sticky: {
      true: {
        position: 'sticky',
        top: 0,
      },
    },
  },
});

const HeaderLeftWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  zIndex: '$HeaderMobile',
  height: '$mobileHeaderHeight',
});

const HeaderRightWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  zIndex: '$HeaderMobile',
  height: '$mobileHeaderHeight',
  gap: '16px',
});

const StyledKexLink = styled(KexLink, {
  ml: 4,
  mb: 0,
});

const Placeholder = styled('div', {
  w: '100%',
  minH: '$sizes$mobileHeaderHeight',
  position: 'absolute',
});
