type MenuStateType = {
  menuIsOpen: boolean;
  mountMenu: boolean;
};

type MenuActionType = {
  id?: number | null;
  type: 'mountAndOpen' | 'closeMenu' | 'unMountMenu';
};

const menuReducer = (state: MenuStateType, action: MenuActionType) => {
  switch (action.type) {
    case 'mountAndOpen': {
      return {
        ...state,
        menuIsOpen: true,
        mountMenu: true,
        menuId: action.id || null,
      };
    }
    case 'closeMenu': {
      return {
        ...state,
        menuIsOpen: false,
        menuId: action.id || null,
      };
    }
    case 'unMountMenu': {
      return {
        ...state,
        menuIsOpen: false,
        mountMenu: false,
        menuId: action.id || null,
      };
    }
  }
};

export default menuReducer;
