import { styled } from 'stitches.config';
import { GetCart } from 'Shared/Cart/Cart';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

function CartNotifier() {
  const { languageRoute } = useAppSettingsData();
  const { cart, isLoading, isCartEmpty } = GetCart(languageRoute);

  if (isLoading || isCartEmpty) return <></>;

  const isBiggerNotifierStyle = (cart?.numberOfItems || 0) >= 10;

  return (
    <CartItemsNotifier bigger={isBiggerNotifierStyle}>
      {cart?.numberOfItems}
    </CartItemsNotifier>
  );
}

const CartItemsNotifier = styled('span', {
  position: 'absolute',
  t: 0,
  r: -1,
  backgroundColor: '$JE68GreenPrimary',
  color: '$textSecondary',
  fontFamily: '$fontSecondary500',
  borderRadius: '50%',
  fs: 4,
  minW: 3,
  minH: 3,
  lineHeight: '$lh1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'hidden',
  zIndex: '$Notification',
  variants: {
    bigger: {
      true: {
        minW: 4,
        minH: 4,
        t: -1,
        r: -1,
      },
    },
  },
});

export default CartNotifier;
