import { BackIcon, LoadingCircle } from 'Atoms/Icons';
import IconLink from 'Atoms/Links/IconLink';
import Overlay from 'Atoms/Overlay/Overlay';
import { QuickSearch } from 'Pages/SearchPage/Search';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'Shared/Common/debounce';
import {
  EventDispatcher,
  ON_MOBILE_QUICKSEARCH_TOGGLE,
} from 'Shared/Common/EventDispatcher';
import useOutsideClick from 'Shared/Hooks/useOutsideClick';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { styled } from 'stitches.config';
import {
  useKexNavigate,
  useKexRouterCurrentPage,
} from '../../../Kex/KexRouter/KexRouter';
import SearchInput from '../SearchInput';
import SearchPhraseResult from '../../../Models/Search/SearchPhraseResult.interface';
import { H5 } from 'Atoms/Typography/Headings/Heading';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import SearchCard from 'Organisms/ProductCard/SearchCard';
import SearchLinkResultModel from 'Models/Search/SearchLinkResultModel.interface';
import React from 'react';
import { canUseDOM } from 'Shared/Common/Helpers';
import { useUserStateData } from '../../../Shared/Providers/UserContextProvider';

type PropTypes = {
  onMenuClose: () => void;
};

function MobileQuickSearch({ onMenuClose }: PropTypes) {
  const {
    staticPages: { searchPage },
    languageRoute,
  } = useAppSettingsData();
  const kexNavigate = useKexNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [result, setResult] = useState<SearchPhraseResult>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const page = useKexRouterCurrentPage();
  const onSearchPage = page.pageType === 'SearchPage';

  const {
    account: { query: searchedQuery },
    accountDispatch,
  } = useUserStateData();
  useEffect(() => setQuery(searchedQuery), [searchedQuery]);

  useOutsideClick(wrapperRef, () => {
    isOpen && onToggle();
  });

  const hits =
    result &&
    result?.categoryCollection?.availableItems +
      result?.contentCollection?.availableItems +
      result?.productCollection?.availableItems;

  const onToggle = () => {
    if (!isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
        onMenuClose();
      }, 500);
    }
    setIsOpen(!isOpen);
  };

  const currentUrl = canUseDOM() && window.location.href;

  useEffect(() => {
    onMenuClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  const {
    searchLabels: {
      quickSearchCategories,
      quickSearchPages,
      quickSearchProducts,
      showAllProducts,
    },
  } = useTranslationData();

  const doSearch = () => {
    setIsOpen(false);
    if (query.length > 1) {
      accountDispatch({ type: 'setQuery', query });
      if (!onSearchPage) {
        kexNavigate(`${searchPage}?query=${query}`);
      }
    }
  };

  const doQuickSearch = useCallback(async () => {
    const res = await QuickSearch(
      searchPage,
      query,
      languageRoute,
      setIsLoading
    );
    res && setResult(res);
  }, [searchPage, query, languageRoute, setIsLoading]);

  useEffect(() => {
    debounce(() => {
      if (query.length >= 3) {
        doQuickSearch();
      }
    }, 300);
  }, [query, searchPage, doQuickSearch]);

  useEffect(() => {
    EventDispatcher.subscribe(ON_MOBILE_QUICKSEARCH_TOGGLE, onToggle);

    return () => {
      EventDispatcher.unsubscribe(ON_MOBILE_QUICKSEARCH_TOGGLE, onToggle);
    };
  });

  return (
    <Wrapper ref={wrapperRef} transform={isOpen}>
      <Top>
        <BackButton onClick={() => setIsOpen(false)} aria-label={'back button'}>
          <BackIcon color="secondary" size="s" />
        </BackButton>
        {isOpen && (
          <SearchInput
            doSearch={doSearch}
            query={query}
            setQuery={setQuery}
            inputRef={inputRef}
            css={{ h: 12 }}
          />
        )}
      </Top>
      <QuickSearchWrapper>
        {!!hits && hits > 0 && (
          <InnerWrapper>
            <Grid>
              <Row>
                {result?.productCollection &&
                  result.productCollection.availableItems > 0 && (
                    <>
                      <H5 css={HeaderStyle}>
                        {`${quickSearchProducts} (${result.productCollection.availableItems})`}
                      </H5>

                      {result?.productCollection?.items.map(
                        (item: SearchLinkResultModel, index: number) => {
                          if (index < 3) {
                            return (
                              <SearchCard
                                key={index + item.id + index}
                                item={item}
                                onClick={onToggle}
                              />
                            );
                          } else
                            return <React.Fragment key={item.id + index} />;
                        }
                      )}
                      <LinkWrapper>
                        <StyledIconLink
                          type="Arrow"
                          href={
                            onSearchPage
                              ? undefined
                              : `${searchPage}?query=${query}`
                          }
                          css={{ marginLeft: 'auto' }}
                          onClick={doSearch}
                        >
                          {showAllProducts}
                        </StyledIconLink>
                      </LinkWrapper>
                    </>
                  )}
              </Row>
              <Row>
                {result?.categoryCollection &&
                  result.categoryCollection.availableItems > 0 && (
                    <>
                      <H5
                        css={HeaderStyle}
                      >{`${quickSearchCategories} (${result.categoryCollection.availableItems})`}</H5>

                      {result?.categoryCollection?.items.map((item: any) => (
                        <StyledIconLink
                          type="Arrow"
                          href={item.url}
                          key={item.id}
                          onClick={() => setIsOpen(false)}
                        >
                          {item.name}
                        </StyledIconLink>
                      ))}
                    </>
                  )}
              </Row>
              <Row>
                {result?.contentCollection &&
                  result.contentCollection.availableItems > 0 && (
                    <H5 css={HeaderStyle}>
                      {`${quickSearchPages} (${result.contentCollection.availableItems})`}
                    </H5>
                  )}
                {result?.contentCollection?.items.map((item: any) => (
                  <StyledIconLink type="Arrow" href={item.url} key={item.id}>
                    {item.name}
                  </StyledIconLink>
                ))}
              </Row>
            </Grid>
          </InnerWrapper>
        )}

        {isLoading && (
          <>
            <LoadingCircle isLoading={isLoading} color="primary" onBackground />
          </>
        )}
      </QuickSearchWrapper>
      {isOpen && <Overlay isVisible={false} />}
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  position: 'absolute',
  l: 0,
  t: 0,
  backgroundColor: '$backgroundPrimary',
  w: '100vw',
  h: '100vh',
  transition: 'transform $300',
  overflow: 'hidden',
  boxShadow: '0 6px 6px -6px rgba(72, 72, 72, 0.3)',
  variants: {
    sticky: {
      true: {
        position: 'fixed',
        t: 0,
        border: 'none',
      },
    },
    transform: {
      true: {
        overflowY: 'scroll',
        transform: 'translateY(0)',
      },
      false: {
        transform: 'translateY(-100%)',
      },
    },
  },
  zIndex: 21,
});

const Top = styled('div', {
  mb: 4,
  p: 4,
});

const StyledIconLink = styled(IconLink, {
  my: 2,
  fontFamily: '$fontSecondary500',
  lineHeight: '$lh15',
});

const LinkWrapper = styled('div', {
  ml: 'auto',
});

const HeaderStyle = {
  fs: 9,
  mb: 4,
};

const Row = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mb: 12,
});

const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  pb: 16,
});

const QuickSearchWrapper = styled('div', {
  color: 'white',
  backgroundColor: '$backgroundPrimary',
});

const InnerWrapper = styled('div', {
  p: 4,
  pt: 8,
  mt: 4,
  w: '100%',
  height: '100%',
  backgroundColor: '$searchBackgroundPrimary',
});

const BackButton = styled('button', {
  wh: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: '50%',
  backgroundColor: '$primary3',
  mb: 8,
});

export default MobileQuickSearch;
