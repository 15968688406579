import { canUseDOM } from './Helpers';

export type TokenType = 'reset' | 'emailConfirmed' | null;
export type TokenInfo = {
  email: string;
  token: string;
  type: TokenType;
};

export const parseQueryParameters = () => {
  const values = window.location.search
    .replace('?', '')
    .split('&')
    .map((param): null | [string, string] => {
      const splitAt = param.indexOf('=');

      if (splitAt === -1) {
        return null;
      }

      return [param.substring(0, splitAt), param.substring(splitAt + 1)];
    })
    .filter((value): value is [string, string] => value !== null);

  return new Map(values);
};

export const NO_TOKEN = { email: '', token: '', type: null };

export const parseTokenFromQuery = (): TokenInfo => {
  if (!canUseDOM()) return NO_TOKEN;
  const mappedParams = parseQueryParameters();

  const email = mappedParams.get('email');

  if (!email) {
    return NO_TOKEN;
  }

  const resetToken = mappedParams.get('resetToken');
  const regToken = mappedParams.get('confirmEmailToken');

  if (resetToken) {
    return { email, token: resetToken, type: 'reset' };
  } else if (regToken) {
    return { email, token: regToken, type: 'emailConfirmed' };
  } else {
    return NO_TOKEN;
  }
};
