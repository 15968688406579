type StateType = {
  favoritesIsOpen: boolean;
  mountFavorites: boolean;
};

type ActionType = 'mountAndOpen' | 'close' | 'unmount';

const favoritesReducer = (state: StateType, action: ActionType) => {
  switch (action) {
    case 'mountAndOpen': {
      return {
        ...state,
        favoritesIsOpen: true,
        mountFavorites: true,
      };
    }
    case 'close': {
      return {
        ...state,
        favoritesIsOpen: false,
      };
    }
    case 'unmount': {
      return {
        ...state,
        mountFavorites: false,
        favoritesIsOpen: false,
      };
    }
  }
};

export default favoritesReducer;
