import { useEffect, useState, useCallback } from 'react';
import { CartIcon, SearchIcon, UserIcon, HeartIcon } from 'Atoms/Icons';
import {
  EventDispatcher,
  ON_MOBILE_QUICKSEARCH_TOGGLE,
} from 'Shared/Common/EventDispatcher';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import CartNotifier from 'Organisms/Header/CartNotifier/CartNotifier';
import AccountModal, { ModalName } from 'Organisms/AccountModal/AccountModal';

import { styled } from 'stitches.config';

import loadable from '@loadable/component';
import KexLink from 'Kex/KexLink';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import {
  NO_TOKEN,
  parseTokenFromQuery,
} from '../../../Shared/Common/UrlParser';
import { GetFavorites } from 'Shared/Favorites/Favorites';
import { updateUrl } from '../../../Shared/Common/Helpers';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';

const MiniCart = loadable(() => import('Organisms/MiniCart/MiniCart'));

type PropTypes = {
  onMenuClose: () => void;
  toggleFavorites: () => void;
};

function MobileMenu({ onMenuClose, toggleFavorites }: PropTypes) {
  const {
    accountDispatch,
    account,
    miniCartDispatch,
    miniCartIsOpen,
    mountMiniCart,
  } = useUserStateData();

  const { languageRoute } = useAppSettingsData();

  const { hasFavorites } = GetFavorites(languageRoute);
  const toggleAccount = useCallback(() => {
    if (account.userMenuIsOpen) onMenuClose();

    accountDispatch({ type: 'toggle' });
  }, [account.userMenuIsOpen, accountDispatch, onMenuClose]);

  const [tokenInfo, setTokenInfo] = useState(parseTokenFromQuery());

  useEffect(() => {
    if (tokenInfo.type === 'emailConfirmed') {
      accountDispatch({ type: 'toggle' });
    }
  }, [tokenInfo.type, accountDispatch]);

  const clearTokens = useCallback(() => {
    updateUrl(document.title, window.location.pathname);
    setTokenInfo(NO_TOKEN);
  }, [setTokenInfo]);

  const { commonLabels } = useTranslationData();

  const {
    user: { authenticated },
  } = useUserStateData();
  const {
    staticPages: { accountPage },
  } = useAppSettingsData();
  return (
    <>
      <Menu>
        <List>
          <WrappingButton
            aria-label={commonLabels && commonLabels.search}
            onClick={() => {
              onMenuClose();
              EventDispatcher.dispatch(ON_MOBILE_QUICKSEARCH_TOGGLE, null);
            }}
          >
            <SearchIcon size="m" color="secondary" />
          </WrappingButton>
          {!authenticated ? (
            <WrappingButton
              aria-label={'account button'}
              onClick={() => {
                toggleAccount();
              }}
            >
              <UserIcon size="m" color="secondary" />
            </WrappingButton>
          ) : (
            <WrappingButton aria-label={'account button'}>
              <KexLink css={{ m: 0 }} href={accountPage} underlined={false}>
                <UserIcon color="primary" size="m" />
              </KexLink>
            </WrappingButton>
          )}
          <WrappingButton
            aria-label={'favorites button'}
            onClick={() => {
              authenticated ? toggleFavorites() : toggleAccount();
            }}
          >
            <HeartIcon
              color={authenticated && hasFavorites ? 'primary' : 'secondary'}
              size="m"
              fillColor={
                authenticated && hasFavorites ? 'primary' : 'transparent'
              }
            />
          </WrappingButton>
          <WrappingButton
            aria-label={'cart button'}
            onClick={() => {
              onMenuClose();
              miniCartDispatch(
                miniCartIsOpen ? 'closeMiniCart' : 'mountAndOpen'
              );
            }}
          >
            <CartIcon size="m" color="secondary" />
            <CartNotifier />
          </WrappingButton>
        </List>
      </Menu>

      {mountMiniCart && (
        <MiniCart
          isOpen={miniCartIsOpen}
          closeMiniCart={() => {
            miniCartDispatch('closeMiniCart');
          }}
        />
      )}

      <AccountModal
        showModal={account.userMenuIsOpen}
        toggle={toggleAccount}
        clearTokens={clearTokens}
        initialModalState={
          tokenInfo.type === 'emailConfirmed' || tokenInfo.type === 'reset'
            ? {
                email: tokenInfo.email,
                token: tokenInfo.token,
                name:
                  tokenInfo.type === 'emailConfirmed'
                    ? ModalName.CONFIRM
                    : ModalName.RESET,
              }
            : {
                name: ModalName.LOGIN,
              }
        }
      />
    </>
  );
}

const Menu = styled('div', {});

const List = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
});

const WrappingButton = styled('button', {
  position: 'relative',
  display: 'flex',
});

export default MobileMenu;
